import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { 
    Main,

    Gimpo,
    Greeting,
    Vision,
    History,
    Organization,
    Ci,
    
    Advisory,
    Mentoring,
    Ambassador,
    Partnership,
    Youthstore,
    Management,

    Social,
    
    Notice,
    Qna,

} from "./pages";
import Header from './Header';
import Footer from './Footer';
import Layout from './Layout';


function App() {
    return (
        <div className="App">
            <Router>
                <Routes>



                    <Route path="/" element={<><Header /><Main /><Footer /></>} />

                    <Route path="/gimpo" element={<><Header /><Layout page={<Gimpo />} /><Footer /></>} />
                    <Route path="/greeting" element={<><Header /><Layout page={<Greeting />} /><Footer /></>} />
                    <Route path="/vision" element={<><Header /><Layout page={<Vision />} /><Footer /></>} />
                    <Route path="/history" element={<><Header /><Layout page={<History />} /><Footer /></>} />
                    <Route path="/organization" element={<><Header /><Layout page={<Organization />} /><Footer /></>} />
                    <Route path="/ci" element={<><Header /><Layout page={<Ci />} /><Footer /></>} />




                    <Route path="/advisory" element={<><Header /><Layout page={<Advisory />} /><Footer /></>} />
                    <Route path="/mentoring" element={<><Header /><Layout page={<Mentoring />} /><Footer /></>} />
                    <Route path="/Ambassador" element={<><Header /><Layout page={<Ambassador />} /><Footer /></>} />
                    <Route path="/partnership" element={<><Header /><Layout page={<Partnership />} /><Footer /></>} />
                    <Route path="/youthstore" element={<><Header /><Layout page={<Youthstore />} /><Footer /></>} />

                    

                    <Route path="/management" element={<><Header /><Layout page={<Management />} /><Footer /></>} />


                    <Route path="/social" element={<><Header /><Layout page={<Social />} /><Footer /></>} />



                    <Route path="/notice" element={<><Header /><Layout page={<Notice />} /><Footer /></>} />
                    <Route path="/qna" element={<><Header /><Layout page={<Qna />} /><Footer /></>} />



                </Routes>
            </Router>
        </div>
    );
}

export default App;
