import '../../css/Advisory.scss';
import React, { useEffect, useState, useRef } from 'react';

const Advisory = () => {

    const committees = [
        {
            title: "장학선정위원회",
            chairman: "대승산업 대표",
            chairman_name: "윤관회 위원장",
            members: [
                {
                    g: "김포지역건축사회 회장",
                    name: "임현기 위원",
                },{
                    g: "한국공인중개사협회 대의원",
                    name: "김수영 위원",
                }
            ],
        },
        {
            title: "기부문화위원회",
            chairman: "(주)대우특수인물 대표 ",
            chairman_name: "김정태 위원장",
            members: [
                {
                    g: "(주)진산건설 대표 ",
                    name: "신승진 위원",
                },{
                    g: "세무사 홍석일사무소 ",
                    name: "홍석일 위원",
                }
            ],
        },
        {
            title: "복지문화위원회",
            chairman: "(주)스위트코리아 대표",
            chairman_name: "최지영 위원장",
            members: [
                {
                    g: "(주)제이제이물산 대표",
                    name: "전시우 위원",
                },{
                    g: "(주)팔도식품 대표",
                    name: "최경선 위원",
                }
            ],
        },
        {
            title: "지역문화위원회",
            chairman: "(주)모젠인터네셔날 대표",
            chairman_name: "박덕현 위원장",
            members: [
                {
                    g: "고촌읍주민자치회 부회장 ",
                    name: "유묘희 위원",
                },
            ],
        },
        {
            title: "체육진흥위원회",
            chairman: "진솔모터스 대표이사 ",
            chairman_name: "이대근 위원장",
            members: [
                {
                    g: "히즈메디병원 본부장",
                    name: "한영훈 위원",
                },
            ],
        },
        {
            title: "기후전략위원회",
            chairman: "(주)대영밴드 대표 ",
            chairman_name: "최형준 위원장",
            members: [
                {
                    g: "(주)나우 대표 ",
                    name: "정준석 위원",
                },
            ],
        },
        {
            title: "국제교류위원회",
            chairman: "(주)성지화학",
            chairman_name: "문사업 위원장",
            members: [
                {
                    g: "(주)가나로 대표",
                    name: "전지우 위원",
                },
            ],
        },
        {
            title: "문화예술위원회",
            chairman: "건영산업 대표 ",
            chairman_name: "최명식 위원장",
            members: [
                {
                    g: "(주)레고리스 대표 ",
                    name: "고재석 위원",
                },{
                    g: "(주)지엘케이글로벌 대표 ",
                    name: "길의석 위원",
                }
            ],
        },
    ];

    return (
        <>
            <div className="advisory_container">
                <div className="advisory_group_wrap">
                    {committees.map((committee, index) => (
                        <div className="advisory_group_box" key={index}>
                            {/* 위원회 제목 */}
                            <div className="advisory_group_tit f_bigger">{committee.title}</div>
                            
                            {/* 위원장 */}
                            <div className="advisory_chairman f_big">
                                <span>{committee.chairman}</span>
                                <span>{committee.chairman_name}</span>
                            </div>
                            
                            {/* 위원 리스트 */}
                            <div className="advisory_member_box f_normal">
                                {committee.members.map((member, memberIndex) => (
                                    <div className="advisory_member_txt" key={memberIndex}>
                                        <span>{member.g}</span>
                                        <span>{member.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Advisory;