import '../../css/Partnership.scss';
import React, { useEffect, useState, useRef } from 'react';

const Partnership = () => {


    const partnerships = [
        {
            name: "김포경찰서",
            img: "/img/org_1.png", // 로고 이미지 경로
        },
        {
            name: "김포대학교",
            img: "/img/org_2.png", // 로고 이미지 경로
        },
        {
            name: "김포 도시",
            img: "/img/org_3.png", // 로고 이미지 경로
        },
        {
            name: "김포시자원봉사센터",
            img: "/img/org_4.png", // 로고 이미지 경로
        },
        {
            name: "김포시장애인체육회",
            img: "/img/org_5.png", // 로고 이미지 경로
        },
        {
            name: "김포시체육회",
            img: "/img/org_6.png", // 로고 이미지 경로
        },
        {
            name: "한국교통장애인협회",
            img: "/img/org_7.png", // 로고 이미지 경로
        },
    ];

    const partnerships2 = [
        {
            name: "세무사홍석일사무소",
            img: "/img/org_1.png",
        },
        {
            name: "오늘도 힘내바",
            img: "/img/org_2.png",
        },
        {
            name: "메리츠화재",
            img: "/img/org_3.png",
        },
        {
            name: "해두리치킨",
            img: "/img/org_4.png",
        },
        {
            name: "윤아네양계장",
            img: "/img/org_5.png",
        },
        {
            name: "장기역 부동산",
            img: "/img/org_6.png",
        },
        {
            name: "김포티브이",
            img: "/img/org_7.png",
        },
        {
            name: "황소곱창",
            img: "/img/org_7.png",
        },
        {
            name: "현지네 캐피탈",
            img: "/img/org_7.png",
        },
        {
            name: "어디든정형외과",
            img: "/img/org_7.png",
        },
    ];

    

    return (
        <>
            <div className="partnership_container">

                <div className="partnership_wrap">
                    <span style={{width:"100%",fontSize:"25px",fontWeight:"400"}}>업무협약</span>
                    {partnerships.map((partner, index) => (
                        <div className="partnership_box" key={index}>
                            {/* 로고 이미지 */}
                            <img src={partner.img} alt={partner.name} className="partnership_img" />
                            
                            {/* 로고 이름 */}
                            <div className="partnership_name f_normal">{partner.name}</div>
                        </div>
                    ))}
                    <div style={{width:"100%",padding:"10px"}}></div>
                    <span style={{width:"100%",fontSize:"25px",fontWeight:"400"}}>기부후원</span>
                    <div style={{width:"100%"}}></div>
                    {partnerships2.map((partner, index) => (
                        <div className="partnership_box" key={index}>
                            {/* <img src={partner.img} alt={partner.name} className="partnership_img" /> */}
                            <div className="partnership_name f_normal">{partner.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};



<div style={{ color: "red", fontSize: "20px", backgroundColor: "yellow" }}>
    인라인 스타일 적용 예제
</div>




export default Partnership;