import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import 'primeicons/primeicons.css';

function Header() {

    const location = useLocation(); // 현재 URL 가져오기
    const [isMenuOpen, setIsMenuOpen] = useState(false); // 메뉴 상태


    const menus = [
        {
            name: "희망연대",
            link: "/gimpo",
            submenus: [
                { name: "김포청년희망연대는", link: "/gimpo" },
                { name: "인사말", link: "/greeting" },
                { name: "비전과 미션", link: "/vision" },
                { name: "연혁", link: "/history" },
                { name: "조직도", link: "/organization" },
                { name: "CI소개", link: "/ci" },
            ],
        },
        {
            name: "함께하는",
            link: "/advisory",
            submenus: [
                { name: "자문위원", link: "/advisory" },
                // { name: "멘토링위원", link: "/mentoring" },
                // { name: "홍보대사", link: "/ambassador" },
                { name: "청년가게", link: "/youthstore" },
                { name: "후원협력", link: "/partnership" },
            ],
        },
        {
            name: "경영지원",
            link: "/management",
            submenus: [{ name: "경영지원본부", link: "/management" }],
        },
        {
            name: "사회공헌",
            link: "/social",
            submenus: [{ name: "사회공헌본부", link: "/social" }],
        },
        // {
        //     name: "공지사항",
        //     link: "/notice",
        //     submenus: [
        //         { name: "공지사항", link: "/notice" },
        //         { name: "문의사항", link: "/qna" },
        //     ],
        // },
        {
            name: "봉사뉴스",
            target:"_blank",
            link: "https://blog.naver.com/PostList.naver?blogId=gimpo0625&from=postList&categoryNo=81",
            submenus: [
                { name: "봉사소식", link: "https://blog.naver.com/PostList.naver?blogId=gimpo0625&from=postList&categoryNo=81", target:"_blank" },
                { name: "응원영상", link: "https://blog.naver.com/PostList.naver?blogId=gimpo0625&from=postList&categoryNo=82", target:"_blank" },
                { name: "언론뉴스", link: "https://blog.naver.com/PostList.naver?blogId=gimpo0625&from=postList&categoryNo=41", target:"_blank" },
                { name: "문의사항", link: "https://blog.naver.com/gimpo0625", target:"_blank" },
            ],
        },
    ];

    const handleMenuToggle = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const handleMenuClick = (link, target = "n") => {
        if (target === "_blank"){
            window.open(link, "_blank", "noopener,noreferrer");
        }else{
            window.location.href = link; // 페이지 이동
            setIsMenuOpen(false); // 메뉴 닫기
        }
    };

    return (
        <>
            <div className="header_container">
                <div className="header_web_container">
                    <div className="header_top_wrap">
                        <div className="header_top_l_box">
                            <a href="#" className='f_big'>
                                <span>문의 031-997-3143</span>
                            </a>
                        </div>
                        <div className="header_top_m_box">
                            <a href="/" className='header_logo_btn'>
                                <img src="/img/logo.png" alt="logo" />
                            </a>
                        </div>
                        <div className="header_top_r_box">
                            <a href="https://blog.naver.com/gimpo0625/" target='_blank' className='header_top_icon header_t_i_n'>
                            </a>
                            <a href="https://www.instagram.com/gimpo.y.p.h.s/" target='_blank' className='header_top_icon header_t_i_i'>
                            </a>
                            <a href="https://www.youtube.com/@%EA%B9%80%ED%8F%AC%EC%B2%AD%EB%85%84%ED%9D%AC%EB%A7%9D%EC%97%B0%EB%8C%80-h3s" target='_blank' className='header_top_icon header_t_i_y'>
                            </a>
                            <a href="http://pf.kakao.com/_Lrbhxj" target='_blank' className='header_top_icon header_t_i_k'>
                            </a>
                        </div>
                    </div>

                    <div className="header_bottom_wrap">
                        <div className="header_main_menu">
                            {menus.map((menu, index) => {
                                // 2차 메뉴 중 현재 URL과 일치하는 것이 있는지 확인
                                const isSubmenuActive = menu.submenus?.some(
                                    (submenu) => location.pathname === submenu.link
                                );

                                return (
                                    <div
                                        key={index}
                                        className={`header_menu_item ${location.pathname === menu.link || isSubmenuActive ? "header_active_menu" : ""
                                            }`}
                                        // onClick={() => handleMenuClick(menu.link)} // 1차 메뉴 클릭 시 이동
                                        onClick={() => handleMenuClick(menu.link, menu.target || "_self")}
                                    >
                                        <span className="header_menu_link">{menu.name}</span>
                                        {menu.submenus && (
                                            <div className="header_submenu">
                                                {menu.submenus.map((submenu, subIndex) => (
                                                    <div
                                                        key={subIndex}
                                                        className={`header_submenu_item ${location.pathname === submenu.link
                                                            ? "header_active_submenu"
                                                            : ""
                                                            }`}
                                                        // onClick={(e) => {
                                                        //     e.stopPropagation(); // 이벤트 전파 중단
                                                        //     handleMenuClick(submenu.link); // 2차 메뉴 클릭 시 이동
                                                        // }}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // 이벤트 전파 중단
                                                            handleMenuClick(submenu.link, submenu.target || "_self")
                                                        }}
                                                    >
                                                        <span>{submenu.name}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>


                <div className="header_mobile_container">

                    <div className="header_mobile_wrap">

                        <div className="header_moblie_l_box">
                            <a href="/" className='header_logo_btn'>
                                <img src="/img/logo.png" alt="logo" />
                            </a>
                        </div>
                        <div className="header_moblie_r_box">
                            <button
                                className="header_hamburger_icon"
                                onClick={handleMenuToggle}
                            >
                                <i className="pi pi-bars"></i>
                            </button>
                        </div>
                    </div>
                    <div
                        className={`mobile_menu_container ${isMenuOpen ? "mobile_menu_open" : ""
                            }`}
                    >
                        <div className="mobile_menu_header">
                            <div className="mobile_contact">
                                <span>문의 031-997-3143</span>
                                <button className="menu_close_button" onClick={handleMenuToggle}>
                                    <FiX />
                                </button>
                            </div>
                            <div className="mobile_icons">
                                <a href="https://blog.naver.com/gimpo0625/" target='_blank' className='header_top_icon header_t_i_n'>
                                </a>
                                <a href="https://www.instagram.com/gimpo.y.p.h.s/" target='_blank' className='header_top_icon header_t_i_i'>
                                </a>
                                <a href="https://blog.naver.com/gimpo0625/" target='_blank' className='header_top_icon header_t_i_y'>
                                </a>
                                <a href="http://pf.kakao.com/_Lrbhxj" target='_blank' className='header_top_icon header_t_i_k'>
                                </a>
                            </div>

                        </div>
                        <div className="mobile_menu">
                            {menus.map((menu, index) => (
                                <div key={index} className="mobile_menu_item">
                                    <span
                                        className={`mobile_menu_link ${location.pathname === menu.link
                                            ? "active_menu"
                                            : ""
                                            }`}
                                        // onClick={() => handleMenuClick(menu.link)}
                                        onClick={() => handleMenuClick(menu.link, menu.target || "_self")}
                                    >
                                        {menu.name}
                                    </span>
                                    {menu.submenus && (
                                        <div className="mobile_submenu">
                                            {menu.submenus.map(
                                                (submenu, subIndex) => (
                                                    <span
                                                        key={subIndex}
                                                        className={`mobile_submenu_item ${location.pathname ===
                                                            submenu.link
                                                            ? "active_submenu"
                                                            : ""
                                                            }`}
                                                        // onClick={() =>
                                                        //     handleMenuClick(
                                                        //         submenu.link
                                                        //     )
                                                        // }
                                                        onClick={() => handleMenuClick(submenu.link, submenu.target || "_self")}
                                                    >
                                                        {submenu.name}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>




            </div>
        </>
    );
}

export default Header;