import React from "react";
import { useLocation } from "react-router-dom";


function Layout({page}) {

    const pageConfigs = {
        "/gimpo": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "희망연대",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "김포청년희망연대는",
            ly_sub_title: "희망찬 김포시를 위한 저희의 네트워크를 이용한 활동은 계속됩니다.",
        },
        "/greeting": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "희망연대",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "인사말",
            ly_sub_title: `안녕하세요. 김포 봉사단체 김포희망연대 입니다.
                            희망찬 김포시를 위해 저희의 네트워크를 이용한 활동은 젊은 김포가 되는 한걸음을 만들기 위해 저희의 활동을 계속됩니다.`,
        },
        "/vision": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "희망연대",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "비전과 미션",
            ly_sub_title: "희망찬 김포시를 위한 저희의 네트워크를 이용한 활동은 계속됩니다.",
        },
        "/history": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "희망연대",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "연혁",
            ly_sub_title:" 희망찬 김포시를 위한 저희의 네트워크를 이용한 활동은 계속됩니다.",
        },
        "/organization": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "희망연대",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "조직도",
            ly_sub_title: "희망찬 김포시를 위한 저희의 네트워크를 이용한 활동은 계속됩니다.",
        },
        "/ci": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "희망연대",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "CI소개",
            ly_sub_title: "",
        },







        "/advisory": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "함께하는",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "자문위원",
            ly_sub_title: "희망찬 김포시를 위한 저희의 네트워크를 이용한 활동은 계속됩니다.",
        },
        "/mentoring": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "함께하는",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "멘토링위원",
            ly_sub_title: "희망찬 김포시를 위한 저희의 네트워크를 이용한 활동은 계속됩니다.",
        },
        "/ambassador": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "함께하는",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "홍보대사",
            ly_sub_title: "희망찬 김포시를 위한 저희의 네트워크를 이용한 활동은 계속됩니다.",
        },
        "/partnership": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "함께하는",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "후원협력",
            ly_sub_title: "희망찬 김포시를 위한 저희의 네트워크를 이용한 활동은 계속됩니다.",
        },
        "/youthstore": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "함께하는",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "기부문화 실천하는 청년가게 대표",
            ly_sub_title: "김포청년희망연대와 함께 지역의 건강한 변화를 위해 나눔을 실천합니다.<br />후원계좌 : 농협 3154 – 20552 – 550 - 04",
        },





        "/management": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "경영지원",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "경영지원본부",
            ly_sub_title: "",
        },


        "/social": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "사회공헌",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "사회공헌본부",
            ly_sub_title: "",
        },


        "/notice": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "공지사항",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "공지사항",
            ly_sub_title: "희망찬 김포시를 위한 저희의 네트워크를 이용한 활동은 계속됩니다.",
        },
        "/qna": {
            background: "/img/sub_bg_test.jpg",
            bg_title: "공지사항",
            bg_contents: "따스한 정이 있고 흥과 멋이 넘치는 김포에서 추억을 나누세요.",
            ly_title: "문의사항",
            ly_sub_title: "",
        },

        
        // ... 다른 페이지 추가
    };


    const location = useLocation();
    const currentConfig = pageConfigs[location.pathname] || {};

    const menus = [
        {
            name: "희망연대",
            link: "/gimpo",
            submenus: [
                { name: "김포청년희망연대는", link: "/gimpo" },
                { name: "인사말", link: "/greeting" },
                { name: "비전과 미션", link: "/vision" },
                { name: "연혁", link: "/history" },
                { name: "조직도", link: "/organization" },
                { name: "CI소개", link: "/ci" },
            ],
        },
        {
            name: "함께하는",
            link: "/advisory",
            submenus: [
                { name: "자문위원", link: "/advisory" },
                // { name: "멘토링위원", link: "/mentoring" },
                // { name: "홍보대사", link: "/ambassador" },
                { name: "청년가게", link: "/youthstore" },
                { name: "후원협력", link: "/partnership" },
            ],
        },
        {
            name: "경영지원",
            link: "/management",
            submenus: [{ name: "경영지원본부", link: "/management" }],
        },
        {
            name: "사회공헌",
            link: "/social",
            submenus: [{ name: "사회공헌본부", link: "/social" }],
        },
        {
            name: "공지사항",
            link: "/notice",
            submenus: [
                { name: "공지사항", link: "/notice" },
                { name: "문의사항", link: "/qna" },
            ],
        },
    ];

    const handleMenuClick = (link) => {
        window.location.href = link; // 페이지 이동
    };


    return (
        <>
            <div className="layout_container">
                <div
                    className="layout_bg_box"
                    style={{
                        backgroundImage: `url(${currentConfig.background || "/img/default-bg.jpg"})`,
                    }}
                >
                    <div className="layout_text_box">
                        <span className="layout_bg_tit">{currentConfig.bg_title || "페이지 제목"}</span>
                        <span className="layout_bg_exp f_biggest">{currentConfig.bg_contents || "내용이 없습니다."}</span>
                    </div>
                </div>
                
            </div>

            <div className="layout_main_container">
                <div className="layout_sub_menu_wrap">
                    {/* 현재 활성화된 1차 메뉴 이름 표시 */}
                    {menus.map((menu) => {
                        // 현재 URL이 1차 메뉴나 2차 메뉴와 일치하면 1차 메뉴 이름 표시
                        const isCurrentMenuActive =
                            location.pathname === menu.link ||
                            menu.submenus?.some((submenu) => location.pathname === submenu.link);

                        return (
                            isCurrentMenuActive && (
                                <div key={menu.name} className="submenu_title">
                                    <span>{menu.name}</span>
                                </div>
                            )
                        );
                    })}

                    {/* 2차 메뉴 표시 */}
                    <div className="submenu_items">
                        {menus.map((menu) => {
                            const isCurrentMenuActive =
                                location.pathname === menu.link ||
                                menu.submenus?.some((submenu) => location.pathname === submenu.link);

                            // 현재 URL이 속한 1차 메뉴의 2차 메뉴만 렌더링
                            return (
                                isCurrentMenuActive &&
                                menu.submenus?.map((submenu) => (
                                    <div
                                        key={submenu.name}
                                        className={`submenu_item ${
                                            location.pathname === submenu.link ? "active_submenu" : ""
                                        }`}
                                        onClick={() => handleMenuClick(submenu.link)} // 서브 메뉴 클릭 시 이동
                                    >
                                        <span>{submenu.name}</span>
                                    </div>
                                ))
                            );
                        })}
                    </div>
                </div>
                <div className="layout_content_wrap">
                    <div className="layout_content_top_box">
                        <div className="layout_ly_tit f_biggest">{currentConfig.ly_title || ""}</div>
                        {/* <div className="layout_ly_sub_tit">{currentConfig.ly_sub_title || ""}</div> */}
                        <div
                        className="layout_ly_sub_tit"
                        dangerouslySetInnerHTML={{ __html: currentConfig.ly_sub_title || "" }}
                        ></div>
                    </div>
                    <div className="layout_content_bottom_box">
                        {page}
                    </div>
                </div>
            </div>

            
        </>
    );
}
export default Layout;