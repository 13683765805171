import '../../css/Youthstore.scss';
import React, { useEffect, useState, useRef } from 'react';

const Youthstore = () => {
    return (
        <>
            <div className="ys_container">
                <div className="ys_item_wrap">
                    <div className="ys_item">
                        <div className='ys_i_left_box'>
                            <div className='ys_i_img_box'>
                                <img src="/img/youthstore1.jpg" alt="청년가게 1호점" />
                            </div>
                        </div>
                        <div className='ys_i_right_box'>
                            <div className='ys_i_info_box'>
                                <span className='ys_i_info_tit'>청년가게 1호점</span>
                                <table className='ys_i_info_t'>
                                    <tbody>
                                        <tr>
                                            <td className='ys_i_i_t_tit'>상호</td>
                                            <td className='ys_i_i_t_con'>해두리치킨 장기점</td>
                                        </tr>
                                        <tr>
                                            <td className='ys_i_i_t_tit'>대표</td>
                                            <td className='ys_i_i_t_con'>조 춘 상</td>
                                        </tr>
                                        <tr>
                                            <td className='ys_i_i_t_tit'>주소</td>
                                            <td className='ys_i_i_t_con'>경기 김포시 김포한강1로 78번길 7 가동 1층</td>
                                        </tr>
                                        <tr>
                                            <td className='ys_i_i_t_tit'>연락처</td>
                                            <td className='ys_i_i_t_con'>031-997-3143</td>
                                        </tr>
                                        <tr>
                                            <td className='ys_i_i_t_tit'>영업시간</td>
                                            <td className='ys_i_i_t_con'>16:00 ~</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="ys_item">
                        <div className='ys_i_left_box'>
                            <div className='ys_i_img_box'>
                                <img src="/img/youthstore2.jpg" alt="청년가게 1호점" />
                            </div>
                        </div>
                        <div className='ys_i_right_box'>
                            <div className='ys_i_info_box'>
                                <span className='ys_i_info_tit'>청년가게 2호점</span>
                                <table className='ys_i_info_t'>
                                    <tbody>
                                        <tr>
                                            <td className='ys_i_i_t_tit'>상호</td>
                                            <td className='ys_i_i_t_con'>왔다갔다황소곱창 장기점</td>
                                        </tr>
                                        <tr>
                                            <td className='ys_i_i_t_tit'>대표</td>
                                            <td className='ys_i_i_t_con'>신 용 수</td>
                                        </tr>
                                        <tr>
                                            <td className='ys_i_i_t_tit'>주소</td>
                                            <td className='ys_i_i_t_con'>경기 김포시 김포한강1로 78번길 31-13</td>
                                        </tr>
                                        <tr>
                                            <td className='ys_i_i_t_tit'>연락처</td>
                                            <td className='ys_i_i_t_con'>031-982-8892</td>
                                        </tr>
                                        <tr>
                                            <td className='ys_i_i_t_tit'>영업시간</td>
                                            <td className='ys_i_i_t_con'>16:00 ~</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Youthstore;