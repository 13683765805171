
function Footer() {

    return (
        <>
            <div className="footer_cooperation_wrap">
                <div className="footer_cooperation_box">
                    <img src="/img/org_1.png" alt="김포경찰서" />
                    <img src="/img/org_2.png" alt="김포대학교" />
                    <img src="/img/org_3.png" alt="김포시" />
                    <img src="/img/org_4.png" alt="김포시자원봉사센터" />
                    {/* <img src="/img/org_5.png" alt="김포시장애인체육회" /> */}
                    <img src="/img/org_5_20250312.jpg" alt="(사)김포시장애인단체연합회"
                        style={{ paddingLeft: "12px", paddingRight: "12px" }}
                    />
                    <img src="/img/org_6.png" alt="김포시체육회" />
                    <img src="/img/org_7.png" alt="한국교통장애인협회" />
                </div>
            </div>
            <div className="footer_container">
                <div className="footer_wrap">
                    <div className="footer_text_box">
                        <div className="footer_group_box">
                            <span>김포청년희망연대</span>
                            <span>주소 : 경기도 김포시 태장로 795번길 23 607호</span>
                        </div>
                        <div className="footer_group_box">
                            <span>사업자번호 : 872-80-02481</span>
                            <span>E-mail : gimpo0625@naver.com </span>
                        </div>
                        <div className="footer_group_box">
                            <span>Copyright © 김포청년희망연대. All Rights Reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Footer;