import '../../css/Greeting.scss';
import React, { useEffect, useRef } from 'react';

const Greeting = () => {
    return (
        <>
            <div className="page_wrap greeting_page_wrap">
                <div className="g_top_box">
                    <div className="g_t_left_box">
                        <span className="g_t_left_img"></span>
                        <span className="g_t_left_name">김포청년희망연대 회장 박창식</span>
                        <span className="g_t_left_pati1"></span>
                    </div>
                    <div className="g_t_right_box">
                        <div className="g_t_r_txt_box">
                            <span>청년들이 만들어가고</span>
                            <span>청년들이 배우고 나눔을 통해</span>
                            <span>청년들이 성장하는 세상</span>
                            <div style={{ height: '10px' }}></div>
                            <span>소외계층을 위해 그 나눔을 실천하고</span>
                            <span>함께 누리는 청년들의 따뜻한 세상</span>
                            <div style={{ height: '10px' }}></div>
                            <span style={{ color: '#04b5b9' }}>청년들이 함께 하는 세상</span>
                        </div>
                        <div className="g_t_r_txt_box2">
                            <span className='g_t_r_txt_box2_tit'>약력</span>
                            <span className='highlight'>현) 김포청년희망연대 회장</span>
                            <span className='highlight'>현) 바르게살기운동 김포시협의회 부회장</span>
                            <span>전) 김포시학교운영협의회 회장</span>
                            <span>전) 김포일만장학회 수석부회장</span>
                            <span>전) 김포대학교 최고경영자과정(KTEP)14기 회장</span>
                        </div>
                    </div>
                    <div className="g_m_ceomsg_box">
                        <span className="g_m_ceomsg">안녕하세요.</span>
                        <div></div>
                        <span className="g_m_ceomsg">김포청년희망연대 회장 박창식입니다.</span>
                        <div></div>
                        <span className="g_m_ceomsg">
                            소외된 이웃을 돕고 지역발전에 함께 동참하고 있는 비영리 단체입니다. 도움이 필요한 이웃들, 눈물겹게 병마와 사투를
                            벌이고 있는 아이들, 사회의 냉대 속에서도 꿋꿋하게 살아보고자 하는 한부모 가정의 아이들, 북한을 탈출해 어렵게
                            이곳에 정착하려고 하는 이들의 가족을 돕는 활동을 하며, 그들에게 새로운 희망을 불어넣고자 열심히 뛰고 있습니다.
                        </span>
                        <div style={{ height: '15px' }}></div>
                        <span className="g_m_ceomsg">
                            그동안 많은 이웃들이 후원자분들의 온기를 전달받고 미소를 되찾고 있습니다. 여러분의 지속적인 관심과 사랑 덕분에 새 희망의
                            물꼬가 조금씩 트여가고 있습니다.
                        </span>
                        <span className="g_m_ceomsg">
                            지금 이 순간에도 차가운 방에 깔 이불 하나, 아픈 몸 치료할 비용조차 마련할 형편이 안 되는 이웃들이 우리 곁에 있습니다.
                            또한 청년들이 어려움 속에서 포기하지 않고 자립하여 가정을 지켜나가는 젊은이들이 많이 있습니다.
                        </span>
                        <div style={{ height: '15px' }}></div>
                        <span className="g_m_ceomsg">
                            김포청년희망연대는 이들의 고통을 나눠 짊어지고자 합니다. 그들에 대한 무거운 책임감과 사랑으로 힘차게 발걸음을 내딛으려고
                            합니다.
                        </span>
                        <span className="g_m_ceomsg">
                            저희가 하고자 하는 일이 작은 밀알 하나에 지나지 않을 수도 있지만, 누군가의 도움을 기다리는 이웃을 위해 그 작은 밀알을
                            하나씩 뿌리며 희망이라는 이름으로 키워가고자 합니다.
                        </span>
                        <div style={{ height: '15px' }}></div>
                        <span className="g_m_ceomsg">
                            후원자분들의 정성으로 조금씩 변화된 사회를 만들고 싶습니다. 차별과 소외 없이 우리 모두 더불어 함께 살아갈 수 있는
                            희망의 날이 오기를 간절히 소망합니다.
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Greeting;